import { Link } from "gatsby"
import React, { useState } from "react"
import "./archiveBar.scss"
function ArchiveBar({ categories }) {
  const [expand, setExpand] = useState(false)
  return (
    <div className="archive-bar">
      <div className="archive-header">
        <Link to="/resources/latest-news/">Latest news</Link>
      </div>
      <div className="categories">
        <div className="categories-header">
          <p>Browse by category</p>
          <div
            className={`bar ${expand && "close"}`}
            onClick={() => {
              setExpand(!expand)
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <nav className="category">
          <ul className={`menu ${expand && "expand"}`}>
            {categories?.map(archive => {
              if (archive?.count != null) {
                return (
                  <li key={archive?.name}>
                    <Link
                      to={archive?.uri.replace("/category", "")}
                      activeClassName="active"
                    >
                      {archive?.name}
                    </Link>
                  </li>
                )
              }
            })}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default ArchiveBar
