import React from "react"
import "../css/template/blog-template.scss"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Pagination } from "../components/Pagination/Pagination "
import BlogCover from "../components/BlogCover/BlogCover"
import ArchiveBar from "../components/ArchiveBar/ArchiveBar"
import Post from "../components/Post/Post"
import { ApolloProvider } from "@apollo/client"
import { client } from "../hooks/apolloClient"
const BlogIndex = ({ data, pageContext }) => {
  const posts = data.allWpPost.nodes
  const { currentPage, pageCount } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === pageCount
  const prevPage = currentPage - 1 === 1 ? "" : currentPage - 1
  const nextPage = currentPage + 1
  // pagination data
  const paginationData = {
    currentPage,
    pageCount,
    isFirst,
    isLast,
    prevPage,
    nextPage,
  }

  return (
    <ApolloProvider client={client}>
      <Layout isHomePage>
        <div className="blogs">
          <BlogCover />
          <div className="container">
            <div className="blog-wrapper">
              <ArchiveBar categories={data?.allWpCategory?.nodes} />
              <div className="posts-wrapper">
                <div className="title">
                  <h3>
                    Get the latest news about Employment Law, GDPR and Health
                    and Safety affecting your industry.
                  </h3>
                  <p>
                    Click on a latest news story to read or use the links to the
                    left to browse by your business sector or publication date.
                  </p>
                  <p>
                    Any Questions? CALL 03333 215005 or{" "}
                    <a href="/contact-us">Email Us</a>
                  </p>
                </div>
                <div className="posts">
                  {posts?.map(post => {
                    return <Post post={post} key={post.id} />
                  })}
                </div>
                <Pagination
                  paginationData={paginationData}
                  base="resources/latest-news"
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </ApolloProvider>
  )
}

export const Head = () => (
  <Seo title="Opinions & Advice - Wirehouse Employer Service" />
)

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($skip: Int!, $limit: Int!) {
    allWpPost(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip) {
      nodes {
        id
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        nodeType
        featuredImage {
          node {
            slug
            localFile {
              name
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allWpCategory {
      nodes {
        slug
        name
        uri
        count
      }
    }
  }
`
