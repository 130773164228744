import { Link } from "gatsby"
import React from "react"
import "./pagination.scss"

export const Pagination = ({ paginationData, base }) => {
  const siblingCount = 5
  const currentPage = paginationData?.currentPage
  const totalPageCount = paginationData?.pageCount
  let prevDisable = currentPage <= 1 ? "disable" : ""
  let nextDisable = currentPage >= totalPageCount ? "disable" : ""
  const Numrange = () => {
    if (totalPageCount < siblingCount) {
      return (
        <div>
          {Array.from({ length: totalPageCount }, (_, i) => (
            <Link
              activeClassName="active"
              key={`pagination-number${i + 1} `}
              to={`/${base}/${i === 0 ? "" : i + 1}`}
              className="pagination-number"
            >
              {i + 1}
            </Link>
          ))}
        </div>
      )
    }
    if (currentPage < siblingCount) {
      return (
        <div>
          {Array.from({ length: siblingCount }, (_, i) => (
            <Link
              activeClassName="active"
              key={`pagination-number${i + 1} `}
              to={`/${base}/${i === 0 ? "" : i + 1}`}
              className="pagination-number"
            >
              {i + 1}
            </Link>
          ))}
          <span>...</span>
          <Link
            activeClassName="active"
            className="pagination-number"
            to={`/${base}/${totalPageCount}`}
          >
            {totalPageCount}
          </Link>
        </div>
      )
    }
    if (
      currentPage >= siblingCount &&
      currentPage + siblingCount < totalPageCount
    ) {
      return (
        <div>
          <Link
            className="pagination-number"
            to={`/${base}`}
            activeClassName="active"
          >
            1
          </Link>
          <span>...</span>
          {Array.from({ length: currentPage + 3 - currentPage }, (_, i) => (
            <Link
              activeClassName="active"
              key={`pagination-number${currentPage + i + 1}`}
              to={`/${base}/${
                currentPage + i === 0 ? "" : currentPage + i + 1
              }`}
              className="pagination-number"
            >
              {currentPage + i + 1}
            </Link>
          ))}
          <span>...</span>
          <Link
            activeClassName="active"
            className="pagination-number"
            to={`/${base}/${totalPageCount}`}
          >
            {totalPageCount}
          </Link>
        </div>
      )
    }

    if (currentPage + siblingCount >= totalPageCount) {
      const arr = []
      for (let i = totalPageCount; i > totalPageCount - siblingCount; --i) {
        arr.unshift(i)
      }
      return (
        <div>
          <Link
            activeClassName="active"
            className="pagination-number"
            to={`/${base}`}
          >
            1
          </Link>
          <span>...</span>
          {arr.map(i => {
            return (
              <Link
                activeClassName="active"
                key={`pagination-number${i}`}
                to={`/${base}/${i}`}
                className="pagination-number"
              >
                {i}
              </Link>
            )
          })}
        </div>
      )
    }
  }

  return (
    <nav className="pagination">
      <Link
        title="Go to previous page"
        to={`/${base}/${currentPage > 2 ? currentPage - 1 : ""}`}
        className={`pagination-number ${prevDisable}`}
      >
        <div className="prev">«</div>
      </Link>

      <Numrange />
      <Link
        title="Go to next page"
        to={`/${base}/${currentPage + 1}`}
        className={`pagination-number ${nextDisable}`}
      >
        <div className="next">»</div>
      </Link>
    </nav>
  )
}
