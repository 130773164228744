import { Link } from "gatsby"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./post.scss"
import parse from "html-react-parser"
import Image from "../../helper/Image"
function Post({ post }) {
  return (
    <article className="post">
      <div className="post-image">
        <Link to={post?.uri} aria-label={post?.title}>
          {post?.featuredImage ? (
            <Image
              className="image-post"
              image={post?.featuredImage?.node}
              alt={post?.title}
            />
          ) : (
            <StaticImage
              className="image-post"
              src="../../images/post-image.jpg"
              alt={post?.title}
            />
          )}
        </Link>
      </div>
      <div className="entry-title">
        <h2>
          <Link to={post?.uri} aria-label={post?.title}>
            {post?.title}
          </Link>
        </h2>
        <p className="post-meta">{post?.date}</p>
        <div className="post-content">
          {post?.excerpt ? (
            <div className="post-excerpt">{parse(post?.excerpt)}</div>
          ) : (
            <div className="post-excerpt"></div>
          )}
          <Link className="read-more" to={post?.uri} aria-label={post?.title}>
            Read post
          </Link>
        </div>
      </div>
    </article>
  )
}

export default Post
