import React, { useState } from "react"
import "./blogCover.scss"
import CallbackForm from "../../components/CallbackForm/CallbackForm"
function BlogCover() {
  const [openCallback, setOpenCallback] = useState(false)

  return (
    <div className="block-cover">
      <div className="container block-container">
        <div
          className="callback-button"
          onClick={() => {
            setOpenCallback(!openCallback)
          }}
        >
          <p>Request a callback today »</p>
        </div>
        <div className="cover-text">
          <h1>Latest news</h1>
          <p>
            Keep up with the latest news, opinions and developments in Health
            and Safety legislation, GDPR and Employment Law.
          </p>
        </div>
        <CallbackForm open={openCallback} setOpen={setOpenCallback} />
      </div>
    </div>
  )
}

export default BlogCover
